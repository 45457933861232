import React from 'react'
import { withStyles } from '@material-ui/core'
import NavBar from '../components/NavBar'

import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { graphql } from 'gatsby'
import { jpg } from '../utils/strings'
import Layout from '../components/Layout'

const styles = theme => ({
  header: {
    fontFamily: 'Oswald, sans',
    fontSize: '100px',
    fontWeight: 'lighter',
    margin: '0',
  },
  root: {
    padding: '24px',
    maxWidth: '800px',
    margin: 'auto',
    marginBottom: '48px',
    fontSize: '24px',
    fontFamily: 'Aleo, slab-serif',
  },
  sideImage: {
    backgroundSize: 'contain',
    width: '60%',
    padding: '0 24px',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      float: 'none',
    },
  },
  vendors: {
    backgroundColor: '#88A897',
    padding: '16px',
    marginBottom: '24px',
  },
  vendor: {
    color: 'white',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  instaImage: {
    height: '100%',
    float: 'left',
    marginRight: '24px',
  },
  instaLink: {
    color: 'black',
    margin: 'auto',
  },
})

class Cuisine extends React.Component {
  state = {
    instaHover: false,
  }

  render() {
    const { classes, data } = this.props
    const vendors = data.vendors.edges
    const renderAtInsta = () => {
      const rootsandrove = <b>@rootsandrove</b>
      return this.state.instaHover ? <u>{rootsandrove}</u> : rootsandrove
    }
    return (
      <Layout>
        <NavBar />
        <div className={classes.root}>
          <h3 className={classes.header}>THE CUISINE</h3>
          <p>
            <img
              src={data.image.file.url + jpg()}
              alt="Roots and Rove the cuisine"
              className={classes.sideImage}
            />
            Roots & Rove creatively showcases locally-sourced, organic recipes
            for the wholesome food enthusiast. Utilizing a range of seasonal
            produce and ethically-raised meats and fish, this culinary immersion
            aims to highlight the importance of farm fresh ingredients, promote
            a culture of eating local, and sharing a meal with the ones you
            love. Whether you’re looking for a break at your next dinner
            gathering or you want to get cooking yourself, Roots & Rove hopes to
            spark culinary curiosity and creativity within you.
          </p>
          <p>
            Roots & Rove is based in Charlottesville, Virginia and ingredients
            are sourced across Central Virginia and Hampton Roads. While
            individual recipes denote the specific sources of ingredients, here
            are a few local staples:
          </p>
          <div className={classes.vendors}>
            <ul>
              {vendors.map(value => {
                return (
                  <li className={classes.vendor} key={value.node.title}>
                    <OutboundLink
                      href={value.node.link}
                      className={classes.vendor}
                    >
                      {value.node.title}
                    </OutboundLink>
                  </li>
                )
              })}
            </ul>
          </div>

          {/* insta link */}
          <OutboundLink
            href="https://instagram.com/rootsandrove"
            target="_blank"
            rel="noopener"
            onMouseEnter={() => this.setState({ instaHover: true })}
            onMouseLeave={() => this.setState({ instaHover: false })}
          >
            <div>
              <img
                src={`${data.instaImage.file.url}${jpg(128, 128)}`}
                alt="instagram link"
                className={classes.instaImage}
              />
              <p className={classes.instaLink}>
                Follow {renderAtInsta()} on Instagram for more information and
                peek into the kitchen.
              </p>
            </div>
          </OutboundLink>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query cuisine {
    image: contentfulAsset(title: { eq: "the-cuisine-top" }) {
      title
      file {
        url
      }
    }
    vendors: allContentfulVendor(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          link
        }
      }
    }
    instaImage: contentfulAsset(title: { eq: "the-cuisine-insta-link" }) {
      title
      file {
        url
      }
    }
  }
`

export default withStyles(styles)(Cuisine)
